import React, { Component, useCallback, useEffect, useState } from 'react'
import { Row, Col, Card, Tab, Tabs, Table, ProgressBar, Modal, Button, Form, ToastContainer, Toast } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'


import avatar1 from '../../assets/images/user/avatar-1.jpg'
import noavatar from '../../assets/images/user/avatar-0.png'

import clubLogo from '../../images/club_logo.svg'
import tombrasil from '../../assets/images/logos-tom-brasil.jpg'
import alma from '../../assets/images/logo-alma.png'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import { UserCreate } from '../components/userCreate'
// import { OrganizationCreate } from './createOrganization'
import { useForm } from 'react-hook-form'
import { EventCreate } from './createEvent'
import { AddProvider } from './addProvider'
import { MultipleUserCreate } from '../components/multipleUserCreate'
import { SingleProvider } from '../Providers/singleProvider'
import { SingleCheckIn } from './singleCheckIn'
import { SingleCreateUser } from './singleCreateUser'

function ShowEventProvider() {


    const MySwal = withReactContent(Swal)

    const dateConvert = (date: any) => {
        const eventDate = new Date(date)

        const options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        }

        return `${eventDate.toLocaleDateString(
            'pt-br'
        )} ${eventDate.getHours()}:${eventDate.getMinutes().toString().padStart(2, '0')}`
    }

    const [pridedUserList, setPridedUserList] = useState<any[]>([])

    const [noBiometry, setNoBiometry] = useState<any[]>([])

    const [isBepass, setIsBepass] = useState(false)

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
        getValues,
        reset
    } = useForm()


    const [disableSign, setDisableSign] = useState(false)

    const [userList, setUserList] = useState<any[]>([
        {
            "name": "SecCutiry",
            "access_code": null,
            "token": "$2a$10$r8Ah7cr4m1RAgwVAq8oT7.n5gOjqGxQlehvfITN9ZPWbKUkHsJdff",
            "externalId": "190",
            "hikId": "da85ecda9d0011eda8fc0242ac120002",
            "active": true,
            "userAvatar": clubLogo,
            coordinator: "Lucas Chaves",
            "biometricTotal": "150",
            "biometricLeft": "50",
            "syncStatus": [

            ]
        }
    ])


    const userSyncProgress = (partialSync: any, totalSync: any) => {
        return Math.round((totalSync / partialSync) * 100);
    }

    const [isLoading, setLoading] = useState(false)

    const [totalUsers, setTotalUsers] = useState({
        withBiometry: 0,
        withoutBiometry: 0
    })

    const [show, setShow] = useState(false);

    const [showUserBlock, setShowUserBlock] = useState(false);

    const [userFormattedId, setUserFormattedId] = useState('');

    const handleCloseUserBlock = () => setShowUserBlock(false);
    const handleShowUserBlock = () => setShowUserBlock(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [showOrg, setShowOrg] = useState(false);

    const handleShowOrg = () => setShowOrg(true)
    const handleCloseOrg = () => setShowOrg(false)


    const [showConfirm, setShowConfirm] = useState(false);

    const handleShowConfirm = () => setShowConfirm(true)
    const handleCloseConfirm = () => setShowConfirm(false)


    const [userIdCheckIn, setUserIdCheckIn] = useState('');


    const getAllUsers = (eventId, supplierId) => {
        setLoading(true)


        fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.supplier_employees_byEvent}/${eventId}/${supplierId}/true`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            }
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
                setPridedUserList(response.users)
                const noBiometryIdsList = response.users.filter((item) => item.biometry === 0).map(item => item.userId)

                setMessageUserIdList(noBiometryIdsList)

                const enabledCount = response.users.filter(item => item.biometric).length
                setTotalUsers({
                    withBiometry: enabledCount,
                    withoutBiometry: response.length - enabledCount
                })


            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }

    const [userName, setUserName] = useState('')
    const [selectBiometry, setSelectBiometry] = useState('')
    const [messageType, setMessageType] = useState('')

    const searchAllUsers = (e) => {
        setLoading(true)

        const data = {}

        e.preventDefault()

        if (userName) {
            data['name'] = userName
        }

        if (selectBiometry) {
            data['biometry'] = selectBiometry
        }

        fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.supplier_employees_byEvent}/${eventId}/${supplierId}/true`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            },
            body: JSON.stringify(data)
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
                setPridedUserList(response.users)
                const noBiometryIdsList = response.users.filter((item) => item.biometry === 0).map(item => item.userId)

                setMessageUserIdList(noBiometryIdsList)

                const enabledCount = response.users.filter(item => item.biometric).length
                setTotalUsers({
                    withBiometry: enabledCount,
                    withoutBiometry: response.length - enabledCount
                })


            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }

    const [eventData, setEventData] = useState<any>({})

    const getEventDetails = (eventId) => {
        setLoading(true)
        fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.event_full_data}/${eventId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            }
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
                setEventData(response)

            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }

    const [eventNumber, setEventNumber] = useState(0)
    const [entranceList, setEntranceList] = useState<any[]>([])

    const verifyEntrance = (providerId: any) => {
        return entranceList.findIndex(obj => obj.userId === providerId) >= 0 ? entranceList[entranceList.findIndex(obj => obj.userId === providerId)].createdAt : null
    }


    const getEventEntrances = (eventId, supplierId) => {
        fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.event_access_detailed}/${eventId}/${supplierId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            }
        })
            .then((resg) => resg.json())
            .then((response) => {
                if (eventNumber !== response) {
                    setEventNumber(response.length)
                    setEntranceList(response)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }
    const { eventId, supplierId } = useParams()



const [messageUserIdList, setMessageUserIdList] = useState<any[]>([])

const sendMessage = (e) => {

    if (confirm("Confirmar envio?") == false) {
        return false
    }

        
    setLoading(true)

    const data = {
        users: messageUserIdList,
        type: messageType
    }

    e.preventDefault()

    if(!messageUserIdList){
        console.log('<><> oooo <><>')
        return false
    }

    console.log(data)

    fetch(`${BASE.api_user.base_url}${BASE.api_user.send_messages}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: CONFIG.API_KEY
        },
        body: JSON.stringify(data)
    })
        .then((resg) => resg.json())
        .then((response) => {
            setLoading(false)
            // console.log('hecho!')
            setTimeout(() => {
                alert('Mensagem enviada')
            }, 1000);

        })
        .catch((error) => {
            setLoading(false)
            console.log(error)
            setTimeout(() => {
                alert('Mensagem enviada')
            }, 1000);
        })
}


    const getUsersWithoutBiometry = () => {

    
        return false
        fetch(`${BASE.api_user.base_url}${BASE.api_user.without_biometry}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            }
        })
            .then((resg) => resg.json())
            .then((response) => {
                if(response){
                    const currentUserList = response.map((item) => item.id)
                    setMessageUserIdList(currentUserList)
                }
            })
            .catch((error) => {
                console.log(error)
            })
        
    }   


    const whois = sessionStorage.getItem('name')

    useEffect(() => {
        getEventDetails(eventId)
        getAllUsers(eventId, supplierId)
        getEventEntrances(eventId, supplierId)
        getUsersWithoutBiometry()

        if(whois && whois === 'Amanda Schmidt'){
            setIsBepass(true)
        }
        
        setInterval(() => {
            getEventEntrances(eventId, supplierId)
        }, 10000)
    }, [])

    const onSubmit = (data: any) => {
        setDisableSign(true)

        // const username = string_to_slug(data.name)

        fetch(`${BASE.api.base_url}${BASE.api.access_create}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            },
            body: JSON.stringify({
                name: data.name.replace(/^\s+|\s+$/g, ''),
                email: data.email.replace(/^\s+|\s+$/g, ''),
                password: `AM${Math.random().toString(36).slice(-8)}!`,
                role: data.type || 'default'
            })
        })
            .then((res) => res.json())
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const handleInputs = () => {
        console.log('oie!')
        if (userIdCheckIn) {
            fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.create_event_access}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': BASE.api.api_key
                },
                body: JSON.stringify({
                    userId: userIdCheckIn,
                    eventId,
                    supplierId
                })
            })
                .then((res) => res.json())
                .then((response) => {
                    console.log(response)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    const resetForm = () => {
        setDisableSign(false)
        reset()
    }


    const [showToast, setShowToast] = useState(false);
    const sendEmail = (data) => {
        setLoading(true)


        fetch(`${BASE.api_user.base_url}${BASE.api_user.send_email}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            },
            body: JSON.stringify({
                name: data.firstName,
                email: data.email,
                token: data.token,
                subject: 'Cadastro para acesso Maracanã',
                template: 'bepass-default-maracana'
            })
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
                setShowToast(true)
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }

    const excludeFromEvent = (userId:any) => {

        if (confirm("Confirmar exclusão?") == false) {
        return false
        }

        fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.event_remove_user}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            },
            body: JSON.stringify({
                eventId,
                userId
            })
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
                window.location.reload()
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }


    const sendSingleMessage = (type, userId) => {
        const data = {
            users: [userId],
            type: type
        }
            

        if (confirm("Confirmar envio?") == false) {
            return false
            }

        setLoading(false)
          
            fetch(`${BASE.api_user.base_url}${BASE.api_user.send_messages}`, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            },
            body: JSON.stringify(data)
            })
            .then((resg) => resg.json())
            .then((response) => {
            setLoading(false)
            setTimeout(() => {
                alert('Mensagem enviada')
            }, 1000);
            
            })
            .catch((error) => {
            setLoading(false)
            console.log(error)
            setTimeout(() => {
                alert('Mensagem enviada')
            }, 1000);
            })
    }
    

    const removeBiometry = (userId: any) => {
        setLoading(true)
        if (!confirm('Tem certeza?')) {
            setLoading(false)
            return false
        }
        fetch(`${BASE.api.base_url}${BASE.api.remove_biometry}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            },
            body: JSON.stringify({
                user: userId
            })
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
                generateLog({ affectationId: userId })
                getAllUsers(eventId, supplierId)
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }
    const generateLog = (data: any, bioUse:any = 'biometry delete for game') => {
        const requestData = {
            userId: sessionStorage.getItem('token'),
            detail: {
                rule: sessionStorage.getItem('role'),
                process: bioUse
            },
            affectation: data.affectationId
        }
        fetch(`${BASE.api.base_url}${BASE.api.log_create}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            },
            body: JSON.stringify(requestData)
        })
    }

      const getUserImage = (token: any) => {
        fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.get_image_biometry}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            },
            body: JSON.stringify({
                token: token
            })
        })
            .then((resg) => resg.json())
            .then((response) => {

          
                setLoading(false)
                generateLog({ affectationId: token }, 'download user Image')

                MySwal.fire({
                    html:  <img
                      src={`data:image/png;base64,${response.image}`}
                      className="qr-code"
                      alt="Pessoa"
                    />,
                    confirmButtonColor: '#ccc',
                  })
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }
    return (
        <>
            <Row>
                <Col md={8} xl={9}>
                    <Card className="Recent-Users">
                        <Card.Header>
                            <Card.Title as="h5">
                                <i className={`fa fa-circle text-c-green f-12 m-r-15`} />
                                {eventData.name}</Card.Title>
                            <h6 className="text-muted biometry-status m-t-10">
                                {dateConvert(eventData.eventDate)}
                            </h6>

                        </Card.Header>
                        {/* <Card.Body className="px-0 py-2">
                            <Table responsive hover className='display-table'>
                                <tbody>

                                    {userList &&
                                        userList.map((user: any, index) => (

                                            <tr key={index} className="unread">

                                                <td>
                                                    <h6 className="mb-1">{user.name}</h6>
                                                </td>

                                                <td>
                                                    <p className='text-muted  m-t-10'> contato: Mariela Savello (masavelo@seccurity.com | 11 9922.25551)</p>
                                                </td>



                                                <td className='biometric-progress'>

                                                    {user.biometricTotal && userSyncProgress(user.biometricTotal, user.biometricLeft) < 100 ?

                                                        (userSyncProgress(user.biometricTotal, user.biometricLeft) < 0) ?
                                                            <ProgressBar variant="danger" label={'erro'} now={100} /> :
                                                            (<ProgressBar animated label={`${userSyncProgress(user.biometricTotal, user.biometricLeft)}%`} now={userSyncProgress(user.biometricTotal, user.biometricLeft)} />) :
                                                        userSyncProgress(user.biometricTotal, user.biometricLeft) > 0 ?
                                                            (
                                                                <ProgressBar variant="success" label={'completa'} now={100} />
                                                            ) : ''
                                                    }
                                                </td>





                                            </tr>


                                        ))}
                                </tbody>
                            </Table>
                        </Card.Body> */}
                    </Card>
                    {/* 

                    <Row>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <div className="row align-items-center justify-content-center card-active">
                                        <div className="col-6">
                                            <h6 className="text-center m-b-10 d-relative">
                                                <i className="feather icon-chevrons-up f-20 m-r-15 text-c-blue" />
                                                <span className="text-muted m-r-5">Check-in:</span> 1
                                            </h6>
                                          <ProgressBar variant="danger" label={'erro'} now={100} /> 
                                        </div>
                                        <div className="col-6">
                                            <h6 className="text-center  m-b-10">
                                                <span className="text-muted m-r-5">Check-out:</span> 0
                                                <i className="feather icon-chevrons-down f-20 m-l-15 text-c-red" />
                                            </h6>
                                             <ProgressBar variant="danger" label={'erro'} now={100} />
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row> */}

                    {/* 
                    <Row>

                        <Col md={6}>
                            <Card>
                                <Card.Body className="border-bottom">
                                    <div className="row d-flex align-items-center">
                                        <div className="col-auto">
                                            <i className="feather icon-alert-circle f-30 text-c-yellow" />
                                        </div>
                                        <div className="col">
                                            <h3 className="f-w-300">2</h3>
                                            <span className="d-block text-uppercase">Biometrias Incompletas</span>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col md={6}>
                            <Card>
                                <Card.Body className="border-bottom">
                                    <div className="row d-flex align-items-center">
                                        <div className="col-auto">
                                            <i className="feather icon-users f-30 text-c-green" />
                                        </div>
                                        <div className="col">
                                            <h3 className="f-w-300">3</h3>
                                            <span className="d-block text-uppercase">Pessoas Cadastradas</span>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row> */}

                    <Card className="Recent-Users">
                        <Card.Header>

                            <Form onSubmit={(searchAllUsers)}>
                                <Row className="align-items-center">
                                    <Col sm={12} md={2}>
                                        <Card.Title as="h5">Pessoas</Card.Title>
                                    </Col>
                                    <Col sm={12} md={3}>
                                        <Form.Control placeholder="Nome"
                                            onChange={(e) => setUserName(e.target.value)}
                                        />
                                    </Col>
                                    {/* <Col sm={12} md={3}>
                                        <Form.Label htmlFor="inlineFormInputGroupUsername" visuallyHidden>
                                            Data
                                        </Form.Label>
                                        <select
                                            className="form-control"
                                        >
                                            <option selected value="Matarazzo">Função</option>
                                            <option value="Palestra">Segurança</option>
                                            <option value="Conveniencia">Cattering</option>
                                            <option value="Estacionamento">OnBoarding</option>
                                            <option value="Operacional">Operacional</option>
                                        </select>
                                    </Col> */}
                                    <Col sm={12} md={3}>
                                        <select
                                            // value={userData.role}
                                            className="form-control"
                                            onChange={(e) => setSelectBiometry(e.target.value)}
                                        >
                                            <option selected value="null">Biometria : Todos</option>
                                            <option value="dont">Sem Biometria</option>
                                            <option value="have">Com Biometria</option>

                                        </select>
                                    </Col>



                                    <Col sm={12} md={3} lg={2} >
                                        <Button className='w-100' type="submit">Pesquisar</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Header>
                        <Card.Body className="px-0 py-2">
                            <Table responsive hover>
                                <thead>
                                    <tr>
                                        <th>Checkin</th>
                                        <th>Foto</th>
                                        <th>CPF</th>
                                        <th>Nome</th>
                                        <th>Setor</th>
                                        <th>Biometria</th>
                                        {/* <th>Ações</th> */}
                                    </tr>
                                </thead>
                                <tbody>

                                    {pridedUserList &&
                                        pridedUserList.map((user: any, index) => (

                                            <tr key={index} className="unread">
                                                <td>
                                                    {/*  onClick={() => { setUserIdCheckIn(user.userId); handleShowConfirm() }} */}
                                                    <h6 className="text-muted biometry-status m-t-10">
                                                        {verifyEntrance(user.userId) !== null ? dateConvert(verifyEntrance(user.userId)) : 'Não Feito'}
                                                        <i className={`fa fa-circle text-c-${verifyEntrance(user.userId) !== null ? 'green' : 'red'} f-12 m-l-15`} />
                                                    </h6>

                                                    {/* {verifyEntrance(user.userId) !== null ? `` : (
                                                        <Link to="#" onClick={() => { setUserFormattedId(user.userId.replace(/-/g, '')); handleShowUserBlock() }} className="link-coordinator text-c-yellow m-l-15">
                                                            bloquear
                                                        </Link>
                                                    )} */}

                                                </td>
                                                <td>
                                                    <img onClick={() => {user.hasBiometry !== 'no' ? getUserImage(user.token):console.log('no')}} className="rounded-circle" style={{ width: '40px' }} src={
                                                        user.hasBiometry !== 'no'
                                                            // ? `data:image/png;base64,${user.hasBiometry}`
                                                            ? avatar1
                                                            : noavatar
                                                    } alt="activity-user" />
                                                </td>
                                                <td>
                                                    <h6 className="mb-1">{user.document}</h6>
                                                </td>
                                                <td>
                                                    <h6 className="mb-1">{`${user.firstName} ${user.lastName}`}</h6>
                                                </td>
                                                <td>
                                                    <h6 className="mb-1">{user.externalId}</h6>
                                                </td>
                                                <td>
                                                {isBepass === true ? (
                                                    <h6 className="text-muted biometry-status m-t-10" onClick={() => removeBiometry(user.token)}>
                                                        {user.hasBiometry !== 'no' ? 'OK - Refazer?' : 'FALTA'}
                                                        <i className={`fa fa-circle text-c-${user.hasBiometry !== 'no' ? 'green' : 'red'} f-12 m-l-15`} />
                                                    </h6>
                                                ):(
                                                    <h6 className="text-muted biometry-status m-t-10">
                                                        {user.hasBiometry !== 'no' ? 'OK' : 'FALTA'}
                                                        <i className={`fa fa-circle text-c-${user.hasBiometry !== 'no' ? 'green' : 'red'} f-12 m-l-15`} />
                                                    </h6>
                                                )}
                                                </td>
                                                {/* <td>
                                                    <Link to="#" onClick={() => sendEmail(user)} className="label theme-bg2 text-white f-12">
                                                        Enviar e-mail
                                                    </Link>
                                                */} <td>

                                                    <Link to="#" onClick={() => sendSingleMessage('email', user.userId)} className="label theme-bg text-white f-12">
                                                        Enviar e-mail
                                                    </Link>

                                                    {whois === 'Amanda Schmidt' && (
                                                        <>
                                                        <Link to="#" onClick={() => sendSingleMessage('whats',user.userId)} className="label theme-bg2 text-white f-12">
                                                        Enviar whatsapp
                                                    </Link>

                                                    <Link to="#" onClick={() => sendSingleMessage('sms',user.userId)} className="label theme-bg5 text-white f-12">
                                                        Enviar sms
                                                    </Link></>
                                                    )}

                                                    <Link to="#" onClick={() => { excludeFromEvent(user.userId) }} className="link-coordinator text-c-red m-l-15">
                                                        excluir
                                                    </Link>
                                                </td> 
                                            </tr>

                                        ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>


                </Col>
                <Col md={4} xl={3}>





                    <MultipleUserCreate eventId={eventId} />

                    <UserCreate eventId={eventId} />
                
                {isBepass === true && (
                    <Card>
                    <Card.Body>
                    <Form onSubmit={(sendMessage)}>
                                <Row className="align-items-center">
                                    <Col sm={12}>
                                        <Card.Title as="h5">Mensageria</Card.Title>
                                    </Col>
                        
                                    <Col sm={12} className='mb-3'>
                                        <select
                                            className="form-control"
                                            onChange={(e) => setMessageType(e.target.value)}
                                        >
                                            <option selected value="null">Selecione</option>
                                            <option value="sms">SMS</option>
                                            <option value="whats">Whatsapp</option>
                                            <option value="email">E-mail</option>

                                        </select>
                                    </Col>

                                    <Col sm={12}>
                                        <Button className='w-100' type="submit">Enviar Onboarding</Button>
                                    </Col>
                                </Row>
                            </Form>
                    </Card.Body>
                </Card>
                )}
               </Col>
            </Row>





            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Criar coordenador</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className="mb-3">

                            <Col>
                                <Form.Label>Nome</Form.Label>
                                <Form.Control
                                    placeholder="Nome Completo"
                                    {...register('name', { required: true })}
                                />
                                {errors.name && (
                                    <span className="label-error">
                                        Você precisa informar um Nome.
                                    </span>
                                )}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>E-mail (opcional)</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="E-mail"
                                    {...register('email')}
                                />
                                {errors.email && (
                                    <span className="label-error">
                                        Você precisa informar um E-mail válido
                                    </span>
                                )}</Col>

                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>Telefone (opcional)</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Telefone"
                                    {...register('phone')}
                                />
                                {errors.email && (
                                    <span className="label-error">
                                        Você precisa informar um Telefone válido
                                    </span>
                                )}</Col>

                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Button
                                        disabled={disableSign}
                                        type="submit"
                                        className="mb-0 w-100"
                                        onClick={() => clearErrors()}
                                    >
                                        Cadastrar Usuário
                                    </Button>
                                </Form.Group>
                            </Col>
                            <Col>
                                {disableSign && (
                                    <Form.Group>
                                        <Button
                                            className="mb-0 btn-success w-100"
                                            onClick={() => resetForm()}
                                        >
                                            Cadastrar Outro
                                        </Button>
                                    </Form.Group>
                                )}
                            </Col>
                        </Row>
                        {/* <Row>
                <p>Não foi possível cadastrar o usuário</p>
              </Row> */}
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Criar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showOrg} onHide={handleCloseOrg}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar Evento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className="mb-3">

                            <Col>
                                <Form.Label>Nome</Form.Label>
                                <Form.Control
                                    placeholder="Nome Completo"
                                    {...register('name', { required: true })}
                                />
                                {errors.name && (
                                    <span className="label-error">
                                        Você precisa informar um Nome.
                                    </span>
                                )}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>Local</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder=""
                                    {...register('email')}
                                />
                            </Col>

                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>Data/Hora</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder=""
                                    {...register('phone')}
                                />
                                {errors.email && (
                                    <span className="label-error">
                                        Você precisa informar um Telefone válido
                                    </span>
                                )}</Col>

                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Button
                                        disabled={disableSign}
                                        type="submit"
                                        className="mb-0 w-100"
                                        onClick={() => clearErrors()}
                                    >
                                        Atualizar
                                    </Button>
                                </Form.Group>
                            </Col>

                        </Row>
                        {/* <Row>
                <p>Não foi possível cadastrar o usuário</p>
              </Row> */}
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseOrg}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showConfirm} onHide={handleCloseConfirm}>
                <Modal.Body>
                    Tem certeza?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseConfirm}>
                        Sair
                    </Button>
                    <Button variant="danger" onClick={() => { handleInputs(); handleCloseConfirm() }}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showUserBlock} onHide={handleCloseUserBlock}>
                <Modal.Body>
                    UUID: {userFormattedId}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseUserBlock}>
                        Sair
                    </Button>
                    <Button variant="danger" onClick={handleCloseUserBlock}>
                        Excluir
                    </Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer containerPosition="fixed" className="p-3 toast-me" position={'bottom-end'}>
                <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide bg='info'>
                    <Toast.Body>E-Mail enviado</Toast.Body>
                </Toast>
            </ToastContainer>
        </>
    )
}

export default ShowEventProvider
