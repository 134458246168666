import React, { Component, useCallback, useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  Tab,
  Tabs,
  Table,
  Button,
  Form,
  Alert,
  FloatingLabel
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import avatar1 from '../../assets/images/user/avatar-1.jpg'
import avatar2 from '../../assets/images/user/avatar-2.jpg'
import logoBePass from '../../assets/images/bepass_logo_default.png'

import {
  BsEmojiSunglasses,
  BsSun,
  BsPeople,
  BsPersonBoundingBox,
  BsEmojiWink
} from 'react-icons/bs'
import { VisitorCreate } from '../components/visitorCreate'
import { AccessLog } from '../components/accessLog'
import { FindUser } from '../components/findUser'
import { OpenGate } from '../components/openGate'
import { FindUserFull } from '../components/findUserFull'
import { TotalUsers } from '../components/totalUsers'
import { DailyAccess } from '../components/dailyAccess'
import { GateStatus } from '../components/gateStatus'
import { useForm } from 'react-hook-form'

import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import Loader from '../../shared/Loader'

function Login() {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    getValues,
    reset
  } = useForm()

  const auth = sessionStorage.getItem('auth')
  if (auth === 'true') {
    window.location.href = '/events/d102f0e4-d3e7-4be8-a862-c4c60358dab2'
  }

  const [loginError, setLoginError] = useState({
    status: false,
    message: ''
  })

  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = (data: any) => {
    setIsLoading(true)
    // if (data.user === 'teste@bepass.com' && data.password === 'test123') {
    //   sessionStorage.setItem('auth', 'true')
    //   return (window.location.href = '/events/d102f0e4-d3e7-4be8-a862-c4c60358dab2')
    // }

    setLoginError({
      status: false,
      message: ''
    })

    fetch(`${BASE.api.base_url}${BASE.api.access_login}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: CONFIG.API_KEY
      },
      body: JSON.stringify({
        login: data.user,
        password: data.password
      })
    })
      .then((resg) => resg.json())
      .then((response) => {

        setIsLoading(false)
        if (response.token) {
          sessionStorage.setItem('auth', 'true')
          sessionStorage.setItem('gate', data.gate)
          sessionStorage.setItem('token', response.id)
          sessionStorage.setItem('name', response.name)
          sessionStorage.setItem('role', response.role)
          sessionStorage.setItem('company', response.companyId)


          if (response.id === 'f103cac3-fc51-43c5-844d-b0e90532c64b') {
            sessionStorage.setItem('currentTotem', '141')
          }else           if (response.id === '2cc02b00-216a-4f56-907f-a897d7cf207d') {
            sessionStorage.setItem('currentTotem', '139')
          } else           if (response.id === '643c1909-3e78-441a-9346-c015e92aba87') {
            sessionStorage.setItem('currentTotem', '143')
          }else           if (response.id === 'e42bdbc5-12ee-42de-b9fd-9e7f8e0528b9') {
            sessionStorage.setItem('currentTotem', '142')
          }else           if (response.id === 'bf999a44-86a6-4f23-9a8c-6eb977c560d8') {
            sessionStorage.setItem('currentTotem', '138')
          }else           if (response.id === 'f13310c9-feec-4b00-8871-e42f1cc1b0ac') {
            sessionStorage.setItem('currentTotem', '140')
          }else           if (response.id === '8e3f7987-9083-4483-9b75-c6115f39343f') {
            sessionStorage.setItem('currentTotem', '137')
          }else{
            sessionStorage.setItem('currentTotem', '139')
          }
          

          if(response.id === 'fecdd07b-ccab-4872-8429-dcd2b658094e'){
            sessionStorage.setItem('role', 'facial')
            return (window.location.href = '/dashboard/coordinator')
          }

          if (response.role === 'coordinator') {
            return (window.location.href = '/dashboard/coordinator')
          }
          if (response.role === 'supplier') {
            return (window.location.href = '/dashboard/provider')
          }
          if (response.role === 'admin') {
            return (window.location.href = '/dashboard')
          }
          if (response.role === 'fanbase') {
            return (window.location.href = '/dashboard/fanbase')
          }
        }

        setLoginError({
          status: true,
          message: 'Usuário ou senha inválidos'
        })
      })
      .catch((error) => {
        setIsLoading(false)
        setLoginError({
          status: true,
          message: 'Usuário ou senha inválidos'
        })
      })
  }

  return (
    <React.Fragment>
      <div className="auth-wrapper">
        <div className="auth-content">
          <Card className="borderless text-center">
            <Card.Body>
              <div className="mb-4">
                {/* <i className="feather icon-unlock auth-icon" /> */}
                <img src={logoBePass} className='login-logo' />
              </div>
              {isLoading ?(
            <Loader/>
          ):(
              <Form onSubmit={handleSubmit(onSubmit)}>
                <FloatingLabel
                  className="mb-3"
                  controlId="floatingPassword"
                  label="Usuário"
                >
                  <Form.Control
                    type="text"
                    placeholder="Usuário"
                    {...register('user', { required: true })}
                  />
                  {errors.user && (
                    <Alert variant="danger">Por favor, informe um e-mail</Alert>
                  )}
                </FloatingLabel>

                <FloatingLabel
                  className="mb-3"
                  controlId="floatingPassword"
                  label="Senha"
                >
                  <Form.Control
                    type="password"
                    placeholder="Senha"
                    {...register('password', { required: true })}
                  />
                  {errors.password && (
                    <Alert variant="danger">Por favor, informe uma senha</Alert>
                  )}
                </FloatingLabel>

                {loginError.status && (
                  <Alert variant="danger">{loginError.message}</Alert>
                )}

                <Form.Group>
                  <Button
                    type="submit"
                    className="mb-0 mt-4 w-100 btn-success"
                  // onClick={() => clearErrors()}
                  >
                    Entrar
                  </Button>
                </Form.Group>
              </Form>
                    )}
            </Card.Body>
      
          </Card>
        </div>
      </div>
      <div className='copyright-botton'>Desenvolvido por <a href="#" className="href">Bepass</a> | Todos os direitos reservados</div>

    </React.Fragment>
  )
}

export default Login
